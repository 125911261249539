import React from 'react';

const ContentLink = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M47.1421 41.3079C46.9147 41.3079 46.6967 41.2179 46.5359 41.0571C46.375 40.8962 46.2846 40.6778 46.2846 40.4503V27.6905H41.1052L38.7377 29.0626C38.6041 29.1071 38.4602 29.1071 38.3266 29.0626H30.3342V29.9545C30.3342 30.1819 30.2438 30.4 30.083 30.5608C29.9221 30.7216 29.7041 30.812 29.4767 30.812C29.2492 30.812 29.0312 30.7216 28.8704 30.5608C28.7096 30.4 28.6191 30.1819 28.6191 29.9545V28.2051C28.6191 27.9777 28.7096 27.7596 28.8704 27.5988C29.0312 27.438 29.2492 27.3476 29.4767 27.3476H38.0862L40.4193 25.9755C40.5659 25.9405 40.7182 25.9405 40.8648 25.9755H47.1421C47.3696 25.9755 47.5876 26.0659 47.7484 26.2267C47.9093 26.3875 47.9997 26.6055 47.9997 26.833V40.5533C47.9744 40.7625 47.8731 40.9551 47.7149 41.0943C47.5568 41.2335 47.3528 41.3094 47.1421 41.3079Z"
      fill="#FCFCFD"
    />
    <path
      d="M46.493 44.4984H28.8284C28.661 44.499 28.4971 44.4504 28.3569 44.359C28.2167 44.2676 28.1065 44.1372 28.0395 43.9838L23.0661 32.7673C22.9999 32.6402 22.9656 32.499 22.9656 32.3557C22.9656 32.2124 22.9999 32.0713 23.0661 31.9441C23.1432 31.8243 23.2496 31.7264 23.3759 31.6603C23.5022 31.5941 23.6438 31.562 23.7863 31.5669H41.4509C41.6183 31.5663 41.7822 31.6149 41.9224 31.7063C42.0626 31.7977 42.1728 31.9281 42.2398 32.0815L47.2141 43.298C47.2898 43.4219 47.3296 43.5643 47.3296 43.7096C47.3296 43.8548 47.2898 43.9972 47.2141 44.1211C47.1369 44.241 47.0296 44.3389 46.9034 44.405C46.7771 44.4712 46.6355 44.5033 46.493 44.4984ZM29.3426 42.7834H45.1557L40.9367 33.2819H25.1236L29.3426 42.7834Z"
      fill="#FCFCFD"
    />
    <path
      d="M24.1968 18.3587C23.9694 18.3587 23.7513 18.2682 23.5905 18.1074C23.4297 17.9466 23.3393 17.7286 23.3393 17.5011V4.74128H18.1598L15.7933 6.1134C15.6597 6.15787 15.5157 6.15787 15.3821 6.1134H7.42403V7.00484C7.42403 7.23227 7.33362 7.45074 7.1728 7.61156C7.01199 7.77237 6.79394 7.86237 6.56651 7.86237C6.33908 7.86237 6.12103 7.77237 5.96021 7.61156C5.7994 7.45074 5.70898 7.23227 5.70898 7.00484V5.25588C5.70898 5.02845 5.7994 4.80998 5.96021 4.64917C6.12103 4.48835 6.33908 4.39836 6.56651 4.39836H15.1761L17.5083 3.02624C17.6549 2.99125 17.8081 2.99125 17.9546 3.02624H24.2311C24.4586 3.02624 24.6766 3.11665 24.8374 3.27746C24.9983 3.43828 25.0887 3.65633 25.0887 3.88376V17.6041C25.0629 17.8193 24.9568 18.0168 24.7914 18.1568C24.6259 18.2968 24.4133 18.3689 24.1968 18.3587Z"
      fill="#B85DFF"
    />
    <path
      d="M23.5434 21.5179H5.87872C5.71136 21.5185 5.54744 21.4699 5.40725 21.3785C5.26707 21.2871 5.15681 21.1567 5.08987 21.0033L0.116402 9.78687C0.0406163 9.66295 0 9.52053 0 9.37527C0 9.23001 0.0406163 9.08759 0.116402 8.96368C0.193528 8.84381 0.299983 8.74593 0.426249 8.67979C0.552515 8.61365 0.694131 8.58157 0.836588 8.58642H18.5012C18.6686 8.58583 18.8325 8.634 18.9727 8.72543C19.1129 8.81685 19.2231 8.94762 19.2901 9.10101L24.2636 20.3171C24.3393 20.441 24.38 20.5834 24.38 20.7287C24.38 20.8739 24.3393 21.0163 24.2636 21.1403C24.1864 21.2601 24.08 21.358 23.9537 21.4241C23.8274 21.4903 23.6858 21.5228 23.5434 21.5179ZM6.3929 19.8029H22.206L17.9871 10.3015H2.17396L6.3929 19.8029Z"
      fill="#B85DFF"
    />
    <path
      d="M42.9627 18.1928C42.7352 18.1928 42.5164 18.1028 42.3555 17.942C42.1947 17.7812 42.1051 17.5627 42.1051 17.3353V12.5674C42.1051 10.748 41.3819 9.00317 40.0953 7.71663C38.8088 6.4301 37.0644 5.70723 35.245 5.70723H30.4767C30.2492 5.70723 30.0312 5.61724 29.8704 5.45642C29.7096 5.29561 29.6191 5.07714 29.6191 4.84971C29.6191 4.62228 29.7096 4.40423 29.8704 4.24341C30.0312 4.0826 30.2492 3.99219 30.4767 3.99219H35.245C37.5165 4.00122 39.6917 4.90788 41.2979 6.51409C42.9041 8.12031 43.8112 10.2959 43.8202 12.5674V17.3353C43.8202 17.5627 43.7298 17.7812 43.569 17.942C43.4081 18.1028 43.1901 18.1928 42.9627 18.1928Z"
      fill="#FCFCFD"
    />
    <path d="M40.3838 16.5703L42.9564 21.0296L45.4946 16.5703H40.3838Z" fill="#FCFCFD" />
    <path
      d="M20.319 43.537H12.8416C10.5673 43.537 8.38596 42.6337 6.77779 41.0255C5.16963 39.4174 4.26636 37.236 4.26636 34.9617V30.5372C4.26636 30.3098 4.35677 30.0913 4.51758 29.9305C4.6784 29.7697 4.89645 29.6797 5.12388 29.6797C5.35131 29.6797 5.56936 29.7697 5.73018 29.9305C5.89099 30.0913 5.9814 30.3098 5.9814 30.5372V34.9617C5.9814 36.7812 6.70385 38.526 7.99039 39.8125C9.27692 41.0991 11.0222 41.8219 12.8416 41.8219H20.319C20.5464 41.8219 20.7644 41.9123 20.9253 42.0732C21.0861 42.234 21.1765 42.452 21.1765 42.6795C21.1765 42.9069 21.0861 43.1249 20.9253 43.2857C20.7644 43.4466 20.5464 43.537 20.319 43.537Z"
      fill="#FCFCFD"
    />
    <path d="M7.66427 31.2952L5.12604 26.8359L2.55347 31.2952H7.66427Z" fill="#FCFCFD" />
  </svg>
);

export default ContentLink;
